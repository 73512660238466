import React from "react";
import BrandLogo from "../icons/logo";
import { Link } from "react-router-dom";
import Close from "../icons/close";
const {
  REACT_APP_TWITTER_URL = "",
  REACT_APP_FACEBOOK_URL = "",
  REACT_APP_YOUTUBE_URL = "",
  REACT_APP_INSTA_URL = "",
} = process.env;

interface MenuPopupProps {
  setMenuModel: (e: boolean) => void;
}

const MenuPopup: React.FC<MenuPopupProps> = ({ setMenuModel }) => {
  return (
    <div id="headlessui-portal-root">
      <div>
        <div>
          <div
            className="relative z-50 overflow-hidden"
            id="headlessui-dialog-:r1q:"
          >
            <div
              className="fixed inset-0 bg-black/60 dark:bg-black/70 opacity-100"
              id="headlessui-dialog-overlay-:r1r:"
            />
            <div className="fixed inset-0">
              <div className="flex justify-end min-h-full ">
                <div
                  className="w-full max-w-md transform overflow-hidden transition-all opacity-100 translate-x-0"
                  id="headlessui-dialog-panel-:r1s:"
                >
                  <div className="overflow-y-auto w-full h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
                    <div className="py-6 px-5">
                      <Link
                        className="ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0 w-24"
                        to="/"
                        title="Kesari"
                      >
                        <BrandLogo />
                      </Link>
                      <div className="flex flex-col mt-5 text-neutral-700 dark:text-neutral-300 text-sm">
                        <span>
                          Business Marketplace for all book your appointment and
                          enjoy.
                        </span>
                        <div className="flex justify-between items-center mt-4">
                          <nav
                            className="nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 "
                            data-nc-id="SocialsList"
                          >
                            <Link
                              to={REACT_APP_TWITTER_URL}
                              target="_blank"
                              className="w-9 h-9 flex items-center justify-center rounded-full bg-neutral-100 text-xl dark:bg-neutral-800 dark:text-neutral-300"
                              title="Facebook"
                            >
                              <i className="lab la-facebook-square" />
                            </Link>
                            <Link
                              className="w-9 h-9 flex items-center justify-center rounded-full bg-neutral-100 text-xl dark:bg-neutral-800 dark:text-neutral-300"
                              to={REACT_APP_FACEBOOK_URL}
                              target="_blank"
                              title="Twitter"
                            >
                              <i className="lab la-twitter" />
                            </Link>
                            <Link
                              className="w-9 h-9 flex items-center justify-center rounded-full bg-neutral-100 text-xl dark:bg-neutral-800 dark:text-neutral-300"
                              to={REACT_APP_YOUTUBE_URL}
                              target="_blank"
                              title="Youtube"
                            >
                              <i className="lab la-youtube" />
                            </Link>
                            <Link
                              className="w-9 h-9 flex items-center justify-center rounded-full bg-neutral-100 text-xl dark:bg-neutral-800 dark:text-neutral-300"
                              to={REACT_APP_INSTA_URL}
                              target="_blank"
                              title="Instagram"
                            >
                              <i className="lab la-instagram" />
                            </Link>
                          </nav>
                          {/* <span className="block">
                            <button className="self-center text-2xl md:text-3xl w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center bg-neutral-100 dark:bg-neutral-800">
                              <span className="sr-only">Enable dark mode</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                                className="w-7 h-7"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                                />
                              </svg>
                            </button>
                          </span> */}
                        </div>
                      </div>
                      <span className="absolute right-2 top-2 p-1">
                        <button
                          className="w-8 h-8 flex items-center justify-center rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-700  focus:outline-none"
                          onClick={() => setMenuModel(false)}
                        >
                          <span className="sr-only">Close</span>
                          <Close />
                        </button>
                      </span>
                    </div>
                    <ul className="flex flex-col py-6 px-2 space-y-1">
                      <li className="text-neutral-900 dark:text-white">
                        <Link
                          to="/"
                          onClick={() => setMenuModel(false)}
                          className="flex w-full px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
                          title="Kesari"
                        >
                          <span className="py-2.5 pr-3 block w-full">Home</span>
                        </Link>
                      </li>
                      <li className="text-neutral-900 dark:text-white">
                        <Link
                          to="/search"
                          onClick={() => setMenuModel(false)}
                          className="flex w-full px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
                          title="Search"
                        >
                          <span className="py-2.5 pr-3 block w-full">
                            Search
                          </span>
                        </Link>
                      </li>
                      <li className="text-neutral-900 dark:text-white">
                        <Link
                          to="/pricing"
                          onClick={() => setMenuModel(false)}
                          className="flex w-full px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
                          title="Pricing"
                        >
                          <span className="py-2.5 pr-3 block w-full">
                            Pricing
                          </span>
                        </Link>
                      </li>
                      <li className="text-neutral-900 dark:text-white">
                        <Link
                          to="/contact"
                          onClick={() => setMenuModel(false)}
                          className="flex w-full px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
                          title="Contact"
                        >
                          <span className="py-2.5 pr-3 block w-full">
                            Contact
                          </span>
                        </Link>
                      </li>
                      <li className="text-neutral-900 dark:text-white">
                        <Link
                          to="/about"
                          onClick={() => setMenuModel(false)}
                          className="flex w-full px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
                          title="About"
                        >
                          <span className="py-2.5 pr-3 block w-full">
                            About Us
                          </span>
                        </Link>
                      </li>
                    </ul>
                    <div className="flex items-center justify-between py-6 px-5">
                      <Link
                        className="inline-block"
                        to="/add-your-business"
                        title="List Your Business"
                      >
                        <button className="self-center text-opacity-90 group px-4 py-2 border border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-gray-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                          List your Business
                        </button>
                      </Link>
                      <Link
                        className="inline-block"
                        to="/auth?page=login"
                        title="Login/SignUp"
                      >
                        <button className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6 ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50">
                          Login/SignUp
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuPopup;
