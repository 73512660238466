import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import BrandLogo from "../icons/logo";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import SearchMobileModel from "../models/search";
import MenuPopup from "../models/menu";
import MenuIcon from "../icons/menu";
const {
  REACT_APP_TWITTER_URL = "",
  REACT_APP_FACEBOOK_URL = "",
  REACT_APP_YOUTUBE_URL = "",
  REACT_APP_INSTA_URL = "",
} = process.env;

const NoFooter: Array<string> = ["/add-your-business", "/auth"];

const Footer = () => {
  const location = useLocation();
  const userD = useSelector((state: any) => state.userSlice.info);

  const [userDetails, setUserDetails] = useState<any>({});
  const [bookingPopup, setBookingPopup] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const booking = searchParams.get("booking");
    if (booking) {
      setBookingPopup(true);
    } else {
      setBookingPopup(false);
    }
  }, [location]);

  const { pathname } = location;

  useEffect(() => {
    if (userD) setUserDetails(userD);
  }, [userD]);

  const [page, setPage] = useState("login");
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get("page");
    if (page) setPage(page);
  }, [location]);

  const [mobileModel, setMobileModel] = useState<boolean>(false);
  const [menuModel, setMenuModel] = useState<boolean>(false);

  if (NoFooter.includes(pathname)) {
    return <></>;
  } else {
    return (
      <>
        <div
          className={`FooterNav block md:!hidden p-2 bg-white dark:bg-neutral-800 fixed top-auto bottom-0 inset-x-0 border-t border-neutral-300 dark:border-neutral-700  transition-transform duration-300 ease-in-out ${
            bookingPopup ? "z-0" : "z-30"
          }`}
        >
          <div className="w-full max-w-lg flex justify-around mx-auto text-sm text-center">
            <button
              className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 ${
                page === "" ? "text-neutral-900 dark:text-neutral-100" : ""
              }`}
              onClick={() => setMobileModel(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
                className={`w-6 h-6  ${page === "" ? "text-red-600" : ""}`}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
              <span
                className={`text-[11px] leading-none mt-1 ${
                  page === "" ? "text-red-600" : ""
                }`}
              >
                Explore
              </span>
            </button>
            <Link
              className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 ${
                page === "wishlists"
                  ? "text-neutral-900 dark:text-neutral-100"
                  : ""
              }`}
              to="/account?page=wishlists"
              title={"wishlist"}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
                className={`w-6 h-6  ${
                  page === "wishlists" ? "text-red-600" : ""
                }`}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                />
              </svg>
              <span
                className={`text-[11px] leading-none mt-1 ${
                  page === "wishlists" ? "text-red-600" : ""
                }`}
              >
                Wishlists
              </span>
            </Link>
            <Link
              className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90`}
              to="/account?page=settings"
              title={"settings"}
            >
              {Object.keys(userDetails).length > 0 && (
                <>
                  <div className="wil-avatar relative flex-shrink-0 inline-flex items-center justify-center dark:text-neutral-300 uppercase font-semibold shadow-inner rounded-full w-8 h-8 sm:w-9 sm:h-9 ring-1 ring-white dark:ring-neutral-900">
                    {userDetails.profile && (
                      <img
                        alt={`${userDetails.firstName} ${userDetails.lastName}`}
                        width={256}
                        height={256}
                        className="absolute inset-0 w-full h-full object-cover rounded-full"
                        style={{ color: "transparent" }}
                        src={userDetails.profile}
                      />
                    )}
                    <span className="wil-avatar__name">
                      {userDetails.firstName
                        ? userDetails.firstName.charAt(0)
                        : ""}
                    </span>
                  </div>
                  <span
                    className={`text-[11px] leading-none mt-1 ${
                      page === "settings" ? "text-red-600" : ""
                    }`}
                  >
                    {userDetails.firstName}
                  </span>
                </>
              )}
              {Object.keys(userDetails).length === 0 && (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                    className="w-6 h-6 "
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                  <span className="text-[11px] leading-none mt-1 ">Log in</span>
                </>
              )}
            </Link>
            <div className="flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 ">
              <button
                className="focus:outline-none flex items-center justify-center"
                onClick={() => setMenuModel(true)}
                title="Menu"
                aria-label="Menu"
                aria-labelledby="Menu"
              >
                <MenuIcon />
              </button>
              <span className="text-[11px] leading-none mt-1">Menu</span>
            </div>
          </div>
        </div>
        <div className="relative py-24 lg:py-28 border-t border-neutral-200 dark:border-neutral-700">
          <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10">
            <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
              <div className="col-span-2 md:col-span-1">
                <Link
                  className="ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0 w-24"
                  to="/"
                  title={"Kesari"}
                >
                  <BrandLogo />
                </Link>
              </div>
              <div className="col-span-2 flex items-center md:col-span-3">
                <div className="nc-SocialsList1 flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start">
                  <Link
                    to={REACT_APP_TWITTER_URL}
                    target="_blank"
                    className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
                    title={"Facebook"}
                  >
                    <i className="lab la-facebook-square" />
                    <span className="hidden lg:block text-sm">Facebook</span>
                  </Link>
                  <Link
                    to={REACT_APP_FACEBOOK_URL}
                    target="_blank"
                    className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
                    title={"Twitter"}
                  >
                    <i className="lab la-twitter" />
                    <span className="hidden lg:block text-sm">Twitter</span>
                  </Link>
                  <Link
                    to={REACT_APP_YOUTUBE_URL}
                    target="_blank"
                    className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
                    title={"YouTube"}
                  >
                    <i className="lab la-youtube" />
                    <span className="hidden lg:block text-sm">Youtube</span>
                  </Link>
                  <Link
                    to={REACT_APP_INSTA_URL}
                    target="_blank"
                    className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
                    title={"Instagram"}
                  >
                    <i className="lab la-instagram" />
                    <span className="hidden lg:block text-sm">Instagram</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="text-sm">
              <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
                About Kesari
              </h2>
              <ul className="mt-5 space-y-4">
                <li>
                  <Link
                    className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                    to="/blogs"
                    title={"Blogs - Kesari"}
                  >
                    Blogs
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                    to="/about"
                    title={"About - Kesari"}
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                    to="/contact"
                    title={"Contact - Kesari"}
                  >
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                    to="/pricing"
                    title={"Pricing - Kesari"}
                  >
                    Pricing
                  </Link>
                </li>
              </ul>
            </div>
            <div className="text-sm">
              <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
                For business
              </h2>

              <ul className="mt-5 space-y-4">
                <li>
                  <Link
                    className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                    to="for-business"
                    title="For Partners"
                  >
                    For partners
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                    to="https://terms.kesari.uk/partner-terms"
                    title="Partner Terms"
                  >
                    Partners Term
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                    to="https://terms.kesari.uk/data-protection"
                    title="Data Protection"
                  >
                    Data Protection
                  </Link>
                </li>
              </ul>
            </div>
            <div className="text-sm">
              <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
                Legal
              </h2>
              <ul className="mt-5 space-y-4">
                <li>
                  <Link
                    className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                    to="https://terms.kesari.uk/privacy-policy"
                    title="Privacy Policy - Kesari"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                    to="https://terms.kesari.uk/terms-service"
                    title="Terms of service - Kesari"
                  >
                    Terms of service
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                    to="https://terms.kesari.uk/terms-use"
                    title="Terms of use - Kesari"
                  >
                    Terms of use
                  </Link>
                </li>
              </ul>
            </div>
            <div className="text-sm">
              <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
                Free Download App
              </h2>
              <ul className="mt-5 space-y-4">
                <li>
                  <Link
                    className="self-center text-opacity-90 group px-5 py-2 border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-lg inline-flex items-center text-sm text-gray-700 text-neutral-50 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 bg-primary-6000 hover:bg-primary-700"
                    to="/download-app"
                    title="Download App - Kesari"
                  >
                    Download App
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {mobileModel && <SearchMobileModel setMobileModel={setMobileModel} />}
        {menuModel && <MenuPopup setMenuModel={setMenuModel} />}
      </>
    );
  }
};

export default Footer;
