import { RouteObject, useNavigate } from "react-router";
import { Navigate } from "react-router-dom";
import { lazy, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setToken } from "../redux/slices/token";
import { apiCall } from "../helpers/api";
import { setDetails } from "../redux/slices/user";

const ProtectedRoute: React.FC<{ element: JSX.Element }> = ({ element }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token: string = localStorage.getItem("token") ?? "";
  const isAuthenticated = token ? true : false;
  useEffect(() => {
    if (token) {
      dispatch(setToken(token));
      fetchUserInfo(token);
    }
  }, []);

  const fetchUserInfo = async (token: string) => {
    try {
      const { userD } = await apiCall("POST", "web/user-info", token);
      if (userD) {
        dispatch(setDetails(userD));
      } else {
        navigate(`/auth?page=login`);
      }
    } catch (error) {
      console.error("Error fetching user info:", error);
      navigate(`/auth?page=login`);
    }
  };

  return isAuthenticated ? element : <Navigate to="/auth?page=login" replace />;
};

const Home = lazy(() => import("../views/home"));
const Search = lazy(() => import("../views/search"));
const Business = lazy(() => import("../views/business"));

const AddBusiness = lazy(() => import("../views/addBusiness"));
const Auth = lazy(() => import("../views/auth"));
const VerifyEmail = lazy(() => import("../views/verify"));
const BusinessVerifyEmail = lazy(() => import("../views/verify/business"));
const StaffVerifyEmail = lazy(() => import("../views/verify/staff"));

const Dashboard = lazy(() => import("../views/account"));

const Pricing = lazy(() => import("../views/subscription"));
const About = lazy(() => import("../views/about"));
const Blogs = lazy(() => import("../views/blogs"));
const BlogsDetails = lazy(() => import("../views/blogs/blogDetails"));
const Contact = lazy(() => import("../views/contact"));
const NotFound = lazy(() => import("../views/notFound"));

// for businesses
const ForBusiness = lazy(() => import("../views/forBusiness"));

// common
const Download = lazy(() => import("../views/download"));

// landing
const BeautyLanding = lazy(() => import("../views/landing/beauty"));
const DogLanding = lazy(() => import("../views/landing/dog"));
const CleaningLanding = lazy(() => import("../views/landing/cleaning"));
const DrivingLanding = lazy(() => import("../views/landing/driving"));
const SolicitorLanding = lazy(() => import("../views/landing/solicitor"));
const TattooLanding = lazy(() => import("../views/landing/tattoo"));
const TrainersLanding = lazy(() => import("../views/landing/trainers"));

const routes: RouteObject[] = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/search",
    element: <Search />,
  },
  {
    path: "/business/:id",
    element: <Business />,
  },
  {
    path: "/add-your-business",
    element: <AddBusiness />,
  },
  {
    path: "/auth",
    element: <Auth />,
  },
  {
    path: "/verify-email/:id",
    element: <VerifyEmail />,
  },
  {
    path: "/business-verify-email/:id",
    element: <BusinessVerifyEmail />,
  },
  {
    path: "/staff-verify-email/:id",
    element: <StaffVerifyEmail />,
  },
  {
    path: "/pricing",
    element: <Pricing />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/for-business",
    element: <ForBusiness />,
  },
  {
    path: "/download-app",
    element: <Download />,
  },
  {
    path: "/blogs",
    element: <Blogs />,
  },
  {
    path: "/blog/:id",
    element: <BlogsDetails />,
  },
  {
    path: "/beauty-wellness-and-spa",
    element: <BeautyLanding />,
  },
  {
    path: "/pet-training-and-walking",
    element: <DogLanding />,
  },
  {
    path: "/home-cleaning-services",
    element: <CleaningLanding />,
  },
  {
    path: "/driving-instructors",
    element: <DrivingLanding />,
  },
  {
    path: "/legal-services-solicitors",
    element: <SolicitorLanding />,
  },
  {
    path: "/tattoo-piercing-services",
    element: <TattooLanding />,
  },
  {
    path: "/personal-training-coaching",
    element: <TrainersLanding />,
  },
  {
    path: "/*",
    element: <NotFound />,
  },
  {
    path: "/account",
    element: <ProtectedRoute element={<Dashboard />} />,
  },
];

export default routes;
