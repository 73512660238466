import React, { Suspense, useEffect } from "react";
import { useRoutes } from "react-router-dom";
import routes from "./routers/index";
import Header from "./components/common/header";
import Footer from "./components/common/footer";
import { setToken } from "./redux/slices/token";
import { useDispatch } from "react-redux";
import { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const tokenD = localStorage.getItem("token");
    if (tokenD) dispatch(setToken(tokenD));

    const storedTheme: string | null = localStorage.getItem("theme");
    if (storedTheme !== null) {
      const theme = storedTheme.toLowerCase() === "true";
      const htmlElement = document.querySelector("html");
      if (htmlElement) {
        if (!theme) {
          htmlElement.classList.add("dark");
        } else {
          htmlElement.classList.remove("dark");
        }
      }
    } else {
      localStorage.setItem("theme", "true");
    }
  }, []);

  return (
    <Suspense fallback="">
      <Helmet>
        <title>
          Kesari - Beauty, Spa, Cleaning, Dog Training, Doctor & Bookings
        </title>
        <meta
          name="description"
          content="Discover Kesari, your ultimate appointment booking solution designed to meet the diverse needs of businesses. Simplify your scheduling tasks and optimize your business operations effortlessly with our user-friendly business management software. Experience a seamless transition to organized efficiency with Kesari."
        />
        <meta
          name="keywords"
          content="appointment booking, beauty salon appointments, nearby beauty parlors, cleaning service appointments, local spas, spa appointment scheduling, dog training centers nearby, dog appointment scheduling, veterinary appointments, dog training appointments, legal appointments, Kesari appointment booking, Kesari scheduling, kesari, doctor appointment, pet doctor appointments, hair salon appointment, kesari booking, kesari bookings, appointment booking system, appointment booking, appointment bookings, online appointment booking, online appointment booking system"
        />
      </Helmet>
      <Toaster />
      <Header />
      <div>{useRoutes(routes)}</div>
      <Footer />
    </Suspense>
  );
}

export default App;
