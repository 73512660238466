import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LocationState {
  info: any;
}

const initialState: LocationState = {
  info: {},
};

const LocationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setLocationDetails(state, action: PayloadAction<string>) {
      state.info = action.payload;
    },
  },
});

export const { setLocationDetails } = LocationSlice.actions;
export default LocationSlice.reducer;
