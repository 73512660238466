import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
  info: any;
}

const initialState: UserState = {
  info: {},
};

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setDetails(state, action: PayloadAction<string>) {
      state.info = action.payload;
    },
  },
});

export const { setDetails } = UserSlice.actions;
export default UserSlice.reducer;
