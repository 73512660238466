const { REACT_APP_IMAGE_HOST } = process.env;

export const userProfileLinkMaker = (
  fileName: string,
  size?: string
): string => {
  if (linkChecker(fileName)) return fileName;
  return `${REACT_APP_IMAGE_HOST}user/profile/${
    size ? `${size}/` : ""
  }${fileName}`;
};

export const staffProfileLinkMaker = (
  fileName: string,
  size?: string
): string => {
  if (linkChecker(fileName)) return fileName;
  return `${REACT_APP_IMAGE_HOST}staff/profile/${
    size ? `${size}/` : ""
  }${fileName}`;
};

export const businessLogoLinkMaker = (
  fileName: string,
  size?: string
): string => {
  if (linkChecker(fileName)) return fileName;
  return `${REACT_APP_IMAGE_HOST}business/logo/${
    size ? `${size}/` : ""
  }${fileName}`;
};

export const businessFeaturedBannerLinkMaker = (
  fileName: string,
  size?: string
): string => {
  if (linkChecker(fileName)) return fileName;
  return `${REACT_APP_IMAGE_HOST}business/featuredBanner/${
    size ? `${size}/` : ""
  }${fileName}`;
};

export const businessBannerLinkMaker = (
  fileName: string,
  size?: string
): string => {
  if (linkChecker(fileName)) return fileName;
  return `${REACT_APP_IMAGE_HOST}business/banners/${
    size ? `${size}/` : ""
  }${fileName}`;
};

const linkChecker = (str: string) => {
  const urlPattern = /^(https?:\/\/)?([\w.]+\.[A-Za-z]{2,})(:[0-9]+)?(\/.*)?$/;
  return urlPattern.test(str);
};
