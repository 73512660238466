import { configureStore } from "@reduxjs/toolkit";
import TokenReducer from "./slices/token";
import UserReducer from "./slices/user";
import LocationReducer from "./slices/location";
import CartReducer from "./slices/cart";

const store = configureStore({
  reducer: {
    tokenSlice: TokenReducer,
    userSlice: UserReducer,
    locationSlice: LocationReducer,
    cartSlice: CartReducer,
  },
});

export default store;
