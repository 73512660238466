import React, { useEffect, useRef, useState } from "react";
import BellIcon from "../../icons/bell";
import BrandLogo from "../../icons/logo";
import Notification from "./notification";
import ProfileDropper from "./profileDropper";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDetails } from "../../../redux/slices/user";
import { apiCall } from "../../../helpers/api";
import SearchSmallIcon from "../../icons/searchSmall";
import TopSearchBar from "./topSearchBar";
import SearchMobileModel from "../../models/search";
import { setLocationDetails } from "../../../redux/slices/location";
import { userProfileLinkMaker } from "../../../helpers/imageLinkMaker";
import FilterIcon from "../../icons/filter";

const Header = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.tokenSlice.token);
  const userD = useSelector((state: any) => state.userSlice.info);

  const [showNotification, setShowNotification] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const isBusinessPage =
    location.pathname.includes("/business/") ||
    location.pathname.includes("/search");

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowNotification(false);
      setShowProfile(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getIpAddress = async () => {
    try {
      const response = await fetch("https://ipapi.co/json");
      const data = await response.json();
      // localStorage.setItem("location", data.city);
      // console.log("data", data);
      dispatch(setLocationDetails(data));
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
  };

  useEffect(() => {
    getIpAddress();
  }, []);

  const [modal, setModal] = useState<boolean>(false);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const banner = searchParams.get("modal");
    const bookingParam = searchParams.get("booking");
    const checkoutParam = searchParams.get("checkout");
    if (banner !== null || bookingParam !== null || checkoutParam !== null) {
      setModal(true);
    } else {
      setModal(false);
    }
  }, [location]);

  const [userDetails, setUserDetails] = useState<any>({});
  const [notificationCount, setNotificationsCount] = useState<number>(0);
  const getUserDetails = async () => {
    try {
      const { userD, nCount } = await apiCall("POST", "web/user-info", token);
      setUserDetails(userD);
      setNotificationsCount(nCount);
      dispatch(setDetails(userD));
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (token) getUserDetails();
  }, [token]);

  useEffect(() => {
    if (userD) setUserDetails(userD);
  }, [userD]);

  const [mobileModel, setMobileModel] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<any[]>([]);

  const getNotificationList = async () => {
    try {
      const { notification } = await apiCall("GET", "web/notifications", token);
      setNotifications(notification);
    } catch (error) {
      console.log(error);
    }
  };

  const LoginAndSignUp = () => {
    return (
      <>
        {Object.keys(userDetails).length > 0 && (
          <>
            <div className="relative flex">
              <button
                className="text-opacity-90 group self-center w-10 h-10 sm:w-12 sm:h-12 hover:bg-gray-100 dark:hover:bg-neutral-800 rounded-full inline-flex items-center justify-center text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 relative"
                type="button"
                onClick={() => {
                  setShowNotification(!showNotification);
                  getNotificationList();
                }}
              >
                {notificationCount > 0 && (
                  <span className="w-2 h-2 bg-blue-500 absolute top-2 right-2 rounded-full" />
                )}
                <BellIcon />
              </button>
              {showNotification && (
                <Notification notifications={notifications} />
              )}
            </div>
            <div className="AvatarDropdown relative flex">
              <button
                className="self-center w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none flex items-center justify-center"
                type="button"
                onClick={() => setShowProfile(!showProfile)}
              >
                <div className="wil-avatar relative flex-shrink-0 inline-flex items-center justify-center dark:text-neutral-300 uppercase font-semibold shadow-inner rounded-full w-8 h-8 sm:w-9 sm:h-9 ring-1 ring-white dark:ring-neutral-900">
                  {userDetails.profile && (
                    <img
                      alt={`${userDetails.firstName} ${userDetails.lastName}`}
                      width={256}
                      height={256}
                      className="absolute inset-0 w-full h-full object-cover rounded-full"
                      style={{ color: "transparent" }}
                      src={userProfileLinkMaker(userDetails.profile, "100x100")}
                    />
                  )}
                  <span className="wil-avatar__name">
                    {userDetails.firstName
                      ? userDetails.firstName.charAt(0)
                      : ""}
                  </span>
                </div>
              </button>
              <div ref={dropdownRef}>
                {showProfile && (
                  <ProfileDropper
                    userD={userDetails}
                    setShowProfile={setShowProfile}
                  />
                )}
              </div>
            </div>
          </>
        )}
        {Object.keys(userDetails).length === 0 && (
          <Link
            className="self-center text-opacity-90 group px-5 py-2 border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-gray-700 text-neutral-50 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 bg-primary-6000 hover:bg-primary-700"
            to="/auth?page=login"
            title="Login - Kesari"
          >
            Login/SignUp
          </Link>
        )}
      </>
    );
  };

  const [searchClick, setSearchClick] = useState(false);
  const [defaultOpen, setDefaultOpen] = useState("");
  const headerSearchClick = () => {
    setSearchClick(!searchClick);
  };

  return (
    <>
      {!isBusinessPage && (
        <>
          <div className="sticky top-0 w-full left-0 right-0 z-40 nc-header-bg shadow-sm dark:border-b dark:border-neutral-700">
            <div className="MainNav2 relative z-10">
              <div className="px-4 h-20 lg:container flex justify-between">
                <div className="hidden md:flex justify-start flex-1 space-x-3 sm:space-x-8 lg:space-x-10">
                  <Link
                    className="ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0 w-24 self-center"
                    to="/"
                    title="Kesari"
                  >
                    <BrandLogo />
                  </Link>
                </div>
                <div className="hidden md:flex flex-shrink-0 justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
                  <div className="hidden lg:flex space-x-1">
                    <Link
                      className="self-center text-opacity-90 group px-4 py-2 border border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-gray-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                      to="/add-your-business"
                      title="List your business"
                    >
                      List your business
                    </Link>
                    <LoginAndSignUp />
                  </div>
                </div>
                <div className="self-center lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
                  <div className="HeroSearchForm2Mobile flex justify-between">
                    <div>
                      <Link
                        className="ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0 w-24 self-center"
                        to="/"
                        title="Kesari"
                      >
                        <BrandLogo />
                      </Link>
                    </div>
                    <div className="my-7 flex justify-center">
                      <Link
                        className="self-center text-opacity-90 group px-4 py-2 border border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-gray-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 mx-2"
                        to="/add-your-business"
                        title="List your business - Kesari"
                      >
                        List your Business
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {isBusinessPage && (
        <>
          <header
            className={`sticky top-0 ${
              !modal ? "z-40" : ""
            } shadow-sm dark:border-b dark:border-neutral-700`}
          >
            <div
              className={`bg-white dark:bg-neutral-900 absolute h-full inset-x-0 top-0 transition-transform will-change-[transform,opacity] ${
                searchClick ? "duration-75 scale-y-[3.4]" : ""
              }`}
            />
            <div className="relative px-4 lg:container h-[88px] flex">
              <div className="flex-1 flex justify-between">
                <div className="relative z-10 hidden md:flex flex-1 items-center">
                  <Link
                    className="ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0 w-24 self-center"
                    to="/"
                    title="Kesari"
                  >
                    <BrandLogo />
                  </Link>
                </div>
                <div className="flex flex-[2] lg:flex-none mx-auto">
                  <div
                    className="flex-1 hidden lg:flex self-center"
                    onClick={headerSearchClick}
                  >
                    <div
                      className={`w-full relative flex items-center justify-between border border-neutral-200 dark:border-neutral-6000 rounded-full shadow hover:shadow-md transition-all ${
                        searchClick ? "invisible" : "visible"
                      }`}
                    >
                      <div className="flex items-center font-medium text-sm">
                        <span
                          className="block pl-5 pr-4 cursor-pointer py-3"
                          onClick={() => setDefaultOpen("search")}
                        >
                          Search
                        </span>
                        <span className="h-5 w-[1px] bg-neutral-300 dark:bg-neutral-700" />
                        <span
                          className="block px-4 cursor-pointer py-3"
                          onClick={() => setDefaultOpen("location")}
                        >
                          Location
                        </span>
                      </div>
                      <div className="flex-shrink-0 ml-auto pr-2 cursor-pointer">
                        <span className="w-8 h-8 flex items-center justify-center rounded-full bg-primary-6000  text-white">
                          <SearchSmallIcon />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="self-center flex-1 lg:hidden w-full max-w-lg mx-auto">
                    <div className="HeroSearchForm2Mobile">
                      <button
                        className="relative flex items-center w-full border border-neutral-200 dark:border-neutral-6000 px-4 py-2 pr-11 rounded-full shadow-lg"
                        onClick={() => setMobileModel(true)}
                      >
                        <SearchSmallIcon />
                        <div className="ml-3 flex-1 text-left overflow-hidden">
                          <span className="block font-medium text-sm">
                            Search
                          </span>
                          <span className="block mt-0.5 text-xs font-light text-neutral-500 dark:text-neutral-400 ">
                            <span className="line-clamp-1">
                              By business, services and location
                            </span>
                          </span>
                        </div>
                        <span className="absolute right-2 top-1/2 transform -translate-y-1/2 w-9 h-9 flex items-center justify-center rounded-full border border-neutral-200 dark:border-neutral-6000 dark:text-neutral-300">
                          <FilterIcon />
                        </span>
                      </button>
                    </div>
                  </div>
                  <TopSearchBar
                    searchClick={searchClick}
                    setSearchClick={setSearchClick}
                    defaultOpen={defaultOpen}
                  />
                </div>
                <div className="hidden md:flex relative z-10 flex-1 justify-end text-neutral-700 dark:text-neutral-100">
                  <div className="flex space-x-1">
                    <Link
                      className="self-center hidden xl:inline-flex px-4 py-2 border border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full items-center text-sm text-gray-700 dark:text-neutral-300 font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                      to="/add-your-business"
                      title="List your business - Kesari"
                    >
                      List your business
                    </Link>
                    <LoginAndSignUp />
                  </div>
                </div>
              </div>
            </div>
          </header>
        </>
      )}
      {mobileModel && <SearchMobileModel setMobileModel={setMobileModel} />}
    </>
  );
};

export default Header;
