import React, { useEffect, useState } from "react";
import UserIcon from "../../icons/user";
import NotesIcon from "../../icons/notes";
import HeartIcon from "../../icons/heart";
import BulbIcon from "../../icons/bulb";
import FloatIcon from "../../icons/float";
import LogOutIcon from "../../icons/logOut";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setToken } from "../../../redux/slices/token";
import { setCartDetails } from "../../../redux/slices/cart";
import { setDetails } from "../../../redux/slices/user";
import { userProfileLinkMaker } from "../../../helpers/imageLinkMaker";

interface ProfileDropperProps {
  userD: any;
  setShowProfile: (e: any) => void;
}

const ProfileDropper: React.FC<ProfileDropperProps> = ({
  userD,
  setShowProfile,
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    if (pathname === "/account") navigate("/");
    localStorage.removeItem("token");
    dispatch(setCartDetails([]));
    dispatch(setDetails(""));
    dispatch(setToken(""));
  };

  const handleCloseDrop = () => {
    setTimeout(() => {
      setShowProfile(false);
    }, 250);
  };

  const storedTheme: string | null = localStorage.getItem("theme");
  let themeDT = true;
  if (storedTheme !== null) {
    themeDT = storedTheme.toLowerCase() === "true";
  }

  const [theme, setTheme] = useState<boolean>(themeDT);

  useEffect(() => {
    const htmlElement = document.querySelector("html");
    if (htmlElement) {
      if (!theme) {
        htmlElement.classList.add("dark");
      } else {
        htmlElement.classList.remove("dark");
      }
    }
  }, [theme]);

  return (
    <div className="absolute z-10 w-screen max-w-[260px] px-4 top-full -right-10 sm:right-0 sm:px-0 opacity-100 translate-y-0">
      <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
        <div className="relative grid grid-cols-1 gap-6 bg-white dark:bg-neutral-800 py-7 px-6">
          <div className="flex items-center space-x-3">
            <div className="wil-avatar relative flex-shrink-0 inline-flex items-center justify-center text-neutral-100 uppercase font-semibold shadow-inner rounded-full w-12 h-12 ring-1 ring-white dark:ring-neutral-900">
              {userD.profile && (
                <img
                  alt={userD.firstName + " " + userD.lastName}
                  width={256}
                  height={256}
                  className="absolute inset-0 w-full h-full object-cover rounded-full"
                  src={userProfileLinkMaker(userD.profile, "100x100")}
                  style={{ color: "transparent" }}
                />
              )}
              <span className="wil-avatar__name">
                {userD.firstName ? userD.firstName.charAt(0) : ""}
              </span>
            </div>
            <div className="flex-grow">
              <h4 className="font-semibold">
                {userD.firstName} {userD.lastName}
              </h4>
              {userD.address && (
                <p className="text-xs mt-0.5">{userD.address}</p>
              )}
            </div>
          </div>
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />
          <Link
            className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
            to="/account?page=settings"
            onClick={handleCloseDrop}
            title="Settings"
          >
            <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
              <UserIcon />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium ">My Account</p>
            </div>
          </Link>
          <Link
            className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
            to="/account?page=bookings"
            onClick={handleCloseDrop}
            title="Bookings"
          >
            <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
              <NotesIcon />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium ">My bookings</p>
            </div>
          </Link>
          <Link
            className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
            to="/account?page=wishlists"
            onClick={handleCloseDrop}
            title="Wishlist"
          >
            <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
              <HeartIcon />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium ">Wishlist</p>
            </div>
          </Link>
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />
          <div className="flex items-center justify-between p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
            <div className="flex items-center">
              <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                <BulbIcon />
              </div>
              <div className="ml-4">
                <p className="text-sm font-medium ">Dark theme</p>
              </div>
            </div>
            <div className="inline-flex">
              <span className="sr-only">Enable dark mode</span>
              <button
                className={`${
                  theme ? "bg-teal-600" : "bg-teal-900"
                } relative inline-flex h-[22px] w-[42px] shrink-0 cursor-pointer rounded-full border-4 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                type="button"
                onClick={() => {
                  setTheme(!theme);
                  localStorage.setItem("theme", !theme ? "true" : "false");
                }}
              >
                <span className="sr-only">Enable dark mode</span>
                <span
                  aria-hidden="true"
                  className={`${
                    theme ? "translate-x-0" : "translate-x-5"
                  } pointer-events-none inline-block h-[14px] w-[14px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </button>
            </div>
          </div>
          <button
            className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
            onClick={() => {
              handleLogout();
              handleCloseDrop();
            }}
          >
            <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
              <LogOutIcon />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium ">Log out</p>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileDropper;
