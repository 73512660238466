import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { apiCall } from "../../helpers/api";

interface SearchMobileModelProps {
  setMobileModel: (e: boolean) => void;
}

interface SearchFormData {
  search: string;
  location: string;
}

const SearchMobileModel: React.FC<SearchMobileModelProps> = ({
  setMobileModel,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);

  const [openTab, setOpenTab] = useState<number>(1);
  const [formData, setFormData] = useState<SearchFormData>({
    search: "",
    location: "",
  });

  const handleSearch = () => {
    searchParams.set("value", formData.search);
    searchParams.set("location", formData.location);
    const newSearch = searchParams.toString();
    navigate(`/search?${newSearch}`);
    setMobileModel(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [locations, setLocations] = useState<any[]>([]);

  const handleLocationSearchList = async (value: string) => {
    if (value) {
      try {
        const { locations } = await apiCall("POST", "web/locations", "", {
          searchValue: value,
        });
        setLocations(locations);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div id="headlessui-portal-root">
      <div>
        <div>
          <div className="HeroSearchFormMobile__Dialog relative z-max">
            <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
              <div className="flex h-full">
                <div className="relative h-full overflow-hidden flex-1 flex flex-col justify-between opacity-100 translate-y-0">
                  <div className="absolute left-4 top-4">
                    <button onClick={() => setMobileModel(false)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                        className="w-5 h-5 text-black dark:text-white"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="pt-12 flex w-full justify-center font-semibold text-sm sm:text-base text-neutral-500 dark:text-neutral-400 space-x-6 sm:space-x-8">
                    <div className="relative focus:outline-none focus-visible:ring-0 outline-none select-none">
                      <div className="text-black dark:text-white">Service</div>
                      {/* <span className="absolute inset-x-0 top-full border-b-2 border-black dark:border-white" /> */}
                    </div>
                    <div className="relative focus:outline-none focus-visible:ring-0 outline-none select-none">
                      <div className="text-black dark:text-white">Business</div>
                    </div>
                    {/* <div className="relative focus:outline-none focus-visible:ring-0 outline-none select-none">
                      <div className="text-black dark:text-white">Product</div>
                    </div> */}
                  </div>
                  <div className="flex-1 pt-3 px-1.5 sm:px-4 flex overflow-hidden">
                    <div className="flex-1 overflow-y-auto hiddenScrollbar py-4">
                      <div id="headlessui-tabs-panel-:r29:">
                        <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                          <div>
                            <div className="w-full space-y-5">
                              {openTab !== 1 && (
                                <div className="w-full bg-white dark:bg-neutral-800 rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]">
                                  <button
                                    className="w-full flex justify-between text-sm font-medium p-4"
                                    onClick={() => setOpenTab(1)}
                                  >
                                    <span className="text-neutral-400">
                                      Search
                                    </span>
                                    <span>
                                      {formData.search
                                        ? formData.search
                                        : "All"}
                                    </span>
                                  </button>
                                </div>
                              )}
                              {openTab === 1 && (
                                <div
                                  className={`w-full bg-white dark:bg-neutral-800 ${
                                    openTab === 1
                                      ? "rounded-2xl shadow-lg"
                                      : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
                                  }`}
                                >
                                  <div>
                                    <div className="p-5">
                                      <span className="block font-semibold text-xl sm:text-2xl">
                                        Search
                                      </span>
                                      <div className="relative mt-5">
                                        <input
                                          className="block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate"
                                          placeholder="Search services, businesses"
                                          name="search"
                                          value={formData.search}
                                          onChange={handleChange}
                                        />
                                        <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            className="w-5 h-5 text-neutral-700 dark:text-neutral-400"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                            />
                                          </svg>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {openTab !== 2 && (
                                <div className="w-full bg-white dark:bg-neutral-800 rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]">
                                  <button
                                    className="w-full flex justify-between text-sm font-medium p-4"
                                    onClick={() => setOpenTab(2)}
                                  >
                                    <span className="text-neutral-400">
                                      Location
                                    </span>
                                    <span>
                                      {formData.location
                                        ? formData.location
                                        : "All"}
                                    </span>
                                  </button>
                                </div>
                              )}
                              {openTab === 2 && (
                                <div
                                  className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
                                    openTab === 2
                                      ? "w-full bg-white dark:bg-neutral-800 overflow-hidden rounded-2xl shadow-lg"
                                      : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
                                  }`}
                                >
                                  <div>
                                    <div className="p-5">
                                      <span className="block font-semibold text-xl sm:text-2xl">
                                        Location
                                      </span>
                                      <div className="relative mt-5">
                                        <input
                                          className="block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate"
                                          name="location"
                                          placeholder="London, manchester, leeds"
                                          value={formData.location}
                                          onChange={(e: any) => {
                                            handleChange(e);
                                            handleLocationSearchList(
                                              e.target.value
                                            );
                                          }}
                                        />
                                        <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            className="w-5 h-5 text-neutral-700 dark:text-neutral-400"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                            />
                                          </svg>
                                        </span>
                                      </div>
                                      {locations && locations.length > 0 && (
                                        <div className="mt-7">
                                          <p className="block font-semibold text-base">
                                            Popular locations
                                          </p>
                                          {locations.length > 0 &&
                                            locations.map((e: any) => {
                                              return (
                                                <div
                                                  className="mt-3"
                                                  key={e._id}
                                                  onClick={() => {
                                                    setFormData((prevData) => ({
                                                      ...prevData,
                                                      location: e.region,
                                                    }));
                                                    localStorage.setItem(
                                                      "location",
                                                      e.region
                                                    );
                                                  }}
                                                >
                                                  <div className="py-2 mb-1 flex items-center space-x-3 text-sm">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      fill="none"
                                                      viewBox="0 0 24 24"
                                                      strokeWidth="1.5"
                                                      stroke="currentColor"
                                                      aria-hidden="true"
                                                      className="w-5 h-5 text-neutral-500 dark:text-neutral-400"
                                                    >
                                                      <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                                                      />
                                                      <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                                                      />
                                                    </svg>
                                                    <span>
                                                      {e.postcode}, {e.region},{" "}
                                                      {e.country_string}
                                                    </span>
                                                  </div>
                                                </div>
                                              );
                                            })}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between">
                    <button
                      type="button"
                      className="underline font-semibold flex-shrink-0"
                      onClick={() => {
                        setFormData({
                          search: "",
                          location: "",
                        });
                        setMobileModel(false);
                      }}
                    >
                      Clear all
                    </button>
                    <button
                      type="submit"
                      className="flex-shrink-0 px-4 py-2.5 cursor-pointer rounded-xl bg-primary-6000 flex items-center justify-center text-neutral-50 focus:outline-none  relative z-20"
                      onClick={handleSearch}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                      </svg>
                      <span className="ml-2">Search</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchMobileModel;
