import React from "react";

interface NotificationProps {
  notifications: Array<any>;
}

const Notification: React.FC<NotificationProps> = ({ notifications }) => {
  // .your-div-class::-webkit-scrollbar {
  //   width: 8px; /* Set the width of the scrollbar */
  // }
  return (
    <div className="absolute z-10 w-screen max-w-xs sm:max-w-sm px-4 top-full -right-28 sm:right-0 sm:px-0 opacity-100 translate-y-0">
      <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5">
        <div
          className="relative grid gap-8 bg-white dark:bg-neutral-800 p-7 notificationScroll"
          style={{ maxHeight: "400px", overflow: "auto" }}
        >
          <h3 className="text-xl font-semibold">Notifications</h3>
          {notifications &&
            notifications.length > 0 &&
            notifications.map((e: any) => {
              return (
                <a
                  key={e._id}
                  className="flex p-2 pr-8 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 relative"
                  href="##"
                >
                  <div className="wil-avatar relative flex-shrink-0 inline-flex items-center justify-center text-neutral-100 uppercase font-semibold shadow-inner rounded-full w-8 h-8 sm:w-12 sm:h-12 ring-1 ring-white dark:ring-neutral-900">
                    {e.profile && (
                      <img
                        alt={e.senderName}
                        width={256}
                        height={256}
                        className="absolute inset-0 w-full h-full object-cover rounded-full"
                        src={e.profile}
                        style={{ color: "transparent" }}
                      />
                    )}
                    <span className="wil-avatar__name">
                      {e.senderName ? e.senderName.charAt(0) : ""}
                    </span>
                  </div>
                  <div className="ml-3 sm:ml-4 space-y-1">
                    <p className="text-sm font-medium text-gray-900 dark:text-gray-200">
                      {e.senderName}
                    </p>
                    <p className="text-xs sm:text-sm text-gray-500 dark:text-gray-400">
                      {e.message}
                    </p>
                    <p className="text-xs text-gray-400 dark:text-gray-400">
                      {e.timeCategory}
                    </p>
                  </div>
                  {!e.read && (
                    <span className="absolute right-1 top-1/2 transform -translate-y-1/2 w-2 h-2 rounded-full bg-blue-500" />
                  )}
                </a>
              );
            })}
          {!notifications ||
            (notifications.length === 0 && <>No Notifications</>)}
        </div>
      </div>
    </div>
  );
};

export default Notification;
