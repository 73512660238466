import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TokenState {
  token: string;
}

const initialState: TokenState = {
  token: "",
};

const TokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
  },
});

export const { setToken } = TokenSlice.actions;
export default TokenSlice.reducer;
