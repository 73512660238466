import { redirect } from "react-router-dom";

export const apiCall = async <T>(
  method: "GET" | "POST" | "PUT" | "DELETE",
  api: string,
  token: string,
  body?: object
): Promise<any> => {
  try {
    const options: RequestInit = {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    if (body) {
      options.body = JSON.stringify(body);
    }
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}${api}`,
      options
    );

    if (!response.ok) {
      if (response.status === 403) {
        console.log("Token Expired");
        sessionStorage.removeItem("token");
        redirect("/login");
      }
      if (response.status === 401) {
        console.log("Unauthorized");
        redirect("/");
      }
      throw new Error(`Request failed with status ${response.status}`);
    }

    return await response.json();
  } catch (error: any) {
    console.log("error", error);
    localStorage.removeItem("token");
    redirect("/login");
    throw error;
  }
};
