import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CartStateProps {
  cart: any[];
  currentItem: any;
}

const initialState: CartStateProps = {
  cart: [],
  currentItem: {},
};

const CartSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCartDetails(state, action: PayloadAction<any[]>) {
      state.cart = action.payload;
    },
    setCurrentItemDetails(state, action: PayloadAction<any>) {
      state.currentItem = action.payload;
    },
  },
});

export const { setCartDetails, setCurrentItemDetails } = CartSlice.actions;
export default CartSlice.reducer;
