import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import SearchIcon from "../../icons/search";
import { apiCall } from "../../../helpers/api";
import LocationIcon from "../../icons/location";
import { useSelector } from "react-redux";
interface SearchFormData {
  search: string;
  location: string;
}
interface TopSearchBarProps {
  searchClick: boolean;
  setSearchClick: (value: boolean) => void;
  defaultOpen: string;
}
const TopSearchBar: React.FC<TopSearchBarProps> = ({
  searchClick,
  setSearchClick,
  defaultOpen,
}) => {
  const locationInfo = useSelector((state: any) => state.locationSlice.info);
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setSearchClick(false);
      setShowLocation(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearch = () => {
    searchParams.set("value", formData.search);
    const locationLocal = JSON.parse(localStorage.getItem("location") || "{}");

    let location: string = "";
    let latitude: string = "";
    let longitude: string = "";
    if (Object.keys(locationLocal).length > 0) {
      location = `${locationLocal.postcode}, ${locationLocal.region}`;
      latitude = locationLocal.latitude;
      longitude = locationLocal.longitude;
    } else {
      location = `${locationInfo.postal}, ${locationInfo.city}`;
      latitude = locationInfo.latitude;
      longitude = locationInfo.longitude;
    }

    searchParams.set("location", location);
    searchParams.set("lat", latitude);
    searchParams.set("long", longitude);
    const newSearch = searchParams.toString();
    navigate({ search: newSearch });
    setSearchClick(false);
  };

  const [formData, setFormData] = useState<SearchFormData>({
    search: "",
    location: "",
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setFormData({
      search: searchParams.get("value") || "",
      location: searchParams.get("location") || "",
    });
  }, [location]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [isLocationFocused, setIsLocationFocused] = useState(false);
  const [locations, setLocations] = useState<any[]>([]);
  const [showLocation, setShowLocation] = useState<boolean>(false);

  const handleLocationSearchList = async (value: string) => {
    if (value) {
      try {
        const { locations } = await apiCall("POST", "web/locations", "", {
          searchValue: value,
        });
        setLocations(locations);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    setIsSearchFocused(defaultOpen === "search" ? true : false);
    setIsLocationFocused(defaultOpen === "location" ? true : false);
    setShowLocation(defaultOpen === "location" ? true : false);
  }, [defaultOpen]);

  const LocationDropDown = () => {
    return (
      <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[400px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-5 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
        <h3 className="block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base text-neutral-800 dark:text-neutral-100">
          Recent searches
        </h3>
        <div className="mt-2">
          {locations.map((e: any) => {
            return (
              <span
                className="flex px-4 sm:px-6 items-center space-x-3 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
                key={e._id}
                onClick={() => {
                  setFormData((prevData) => ({
                    ...prevData,
                    location: `${e.postcode}, ${e.region}`,
                  }));
                  localStorage.setItem("location", JSON.stringify(e));
                  setShowLocation(false);
                }}
              >
                <span className="block text-neutral-400">
                  <LocationIcon />
                </span>
                <span className=" block text-neutral-700 dark:text-neutral-200">
                  {e.postcode}, {e.region}, {e.country_string}
                </span>
              </span>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div
      ref={dropdownRef}
      className={`absolute inset-x-0 top-0 transition-all will-change-[transform,opacity] ${
        searchClick
          ? "visible"
          : "-translate-x-0 -translate-y-[90px] scale-x-[0.395] scale-y-[0.6] opacity-0 invisible pointer-events-none"
      }`}
    >
      <div className="w-full max-w-4xl mx-auto pb-6">
        <div className="nc-HeroSearchFormSmall">
          <ul className="h-[88px] flex justify-center space-x-5 sm:space-x-9">
            <li className="relative flex-shrink-0 flex items-center text-base text-neutral-900 dark:text-neutral-200 font-medium ">
              <div className="relative select-none">
                <span>Service</span>
              </div>
            </li>
            <li className="relative flex-shrink-0 flex items-center text-base text-neutral-900 dark:text-neutral-200 font-medium ">
              <div className="relative select-none">
                <span>Business</span>
              </div>
            </li>
            {/* <li className="relative flex-shrink-0 flex items-center cursor-pointer text-base text-neutral-900 dark:text-neutral-200 font-medium ">
              <div className="relative select-none">
                <span>Product</span>
              </div>
            </li> */}
          </ul>
          <div className="mt-2">
            <div className="relative flex rounded-full border border-neutral-200 dark:border-neutral-700 bg-white dark:bg-neutral-800">
              <div className="relative flex flex-[1.5]">
                <div
                  className={`flex flex-1 relative z-10 [ nc-hero-field-padding--small ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left ${
                    isSearchFocused ? "nc-hero-field-focused--2" : ""
                  }`}
                >
                  <div className="flex-1">
                    <input
                      className="block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-400 xl:text-base font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate"
                      placeholder="Search"
                      name="search"
                      value={formData.search}
                      onFocus={() => {
                        setIsSearchFocused(true);
                        setIsLocationFocused(false);
                        setShowLocation(false);
                      }}
                      onBlur={() => setIsSearchFocused(false)}
                      onChange={handleChange}
                    />
                    <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
                      <span className="line-clamp-1">
                        Services, businesses & etc...
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8" />
              <div className="StayDatesRangeInput z-10 relative flex flex-[1.2]">
                <div
                  className={`flex flex-1 relative z-10 [ nc-hero-field-padding--small ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left ${
                    isLocationFocused ? "nc-hero-field-focused--2" : ""
                  }`}
                >
                  <div className="flex-1">
                    <input
                      className="block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-400 xl:text-base font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate"
                      name="location"
                      placeholder="Location"
                      value={formData.location}
                      onFocus={() => setIsLocationFocused(true)}
                      onBlur={() => setIsLocationFocused(false)}
                      onChange={(e: any) => {
                        handleChange(e);
                        handleLocationSearchList(e.target.value);
                      }}
                    />
                    <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
                      <span className="line-clamp-1">
                        London, Manchester ...
                      </span>
                    </span>
                  </div>
                </div>
                {isLocationFocused && <LocationDropDown />}
              </div> */}
              <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8" />
              <div className="z-10 relative flex flex-[1.2]">
                <div
                  className={`flex flex-1 relative z-10 justify-between  flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left ${
                    isLocationFocused ? "nc-hero-field-focused--2" : ""
                  }`}
                >
                  <div className="flex-1 [ nc-hero-field-padding--small ]">
                    <input
                      className="block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-400 xl:text-base font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate"
                      name="location"
                      placeholder="Location"
                      value={formData.location}
                      onFocus={() => {
                        setIsLocationFocused(true);
                        setShowLocation(true);
                        setIsSearchFocused(false);
                      }}
                      onBlur={() => setIsLocationFocused(false)}
                      onChange={(e: any) => {
                        handleChange(e);
                        handleLocationSearchList(e.target.value);
                      }}
                    />
                    <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
                      <span className="line-clamp-1">
                        London, Birmingham, Manchester & etc...
                      </span>
                    </span>
                  </div>
                  {showLocation && <LocationDropDown />}
                  <div className="relative" />
                  <div className="pr-2">
                    <button
                      className="h-14 w-14 rounded-full bg-primary-6000 hover:bg-primary-700 flex items-center justify-center text-neutral-50 focus:outline-none "
                      type="submit"
                      onClick={handleSearch}
                    >
                      <span className="mr-3 md:hidden">Search</span>
                      <SearchIcon />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopSearchBar;
